import { useFormik } from 'formik';
import { useAuth } from "../../components/auth"
import { loginValidationSchema } from './validationSchema';
import { LoginFrom } from './type';
import { Row, Col, Label, Button, FormFeedback, Spinner } from 'reactstrap'

import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLoaderHelper } from '../../helper/loaderHelper';

import { qboButton, qboLogo, xero, zohoLogo } from '../../constant/icons';
import { connectToAPP } from '../company/helper';
import { useEffect } from 'react';
import { message } from '../../components/toast';
import { post } from '../../helper/api';

const initialValues: LoginFrom = {
  userName: "",
  password: "",
}

const Login = () => {
  const location = useLocation()
  const auth = useAuth()
  const navigate = useNavigate();
  let loginType: string | undefined | null = location.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo"].includes(loginType)) {
    loginType = undefined
  }


  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const userName = searchParams.get('userName');
  const role = searchParams.get('role');
  const {api,dbName,user_name,hostingUrl}=useParams();
  if (loginType && token && userName && role) {
    auth.singleSignOn({
      "token": token,
      "userName": userName,
      "role": role,
      "loginType": loginType
    })
  }
  const errorMsg: any = searchParams.get('errorMsg');
  useEffect(() => {
    if (errorMsg) {
      message(
        "error",
        errorMsg
      );
      const timer = setTimeout(() => {
        navigate("/QBO/login");
      }, 3000); // 3 seconds in milliseconds
      return () => clearTimeout(timer);

    }
  }, [errorMsg]);

 const singInWithOdoo=async()=>{
   
   if(api&&dbName&&user_name&&hostingUrl){
      let result=await post("/odooLogin",{
        api,dbName,user_name,hostingUrl
       })
   }
 }

  let icon = ""
  if (loginType) {
    if (loginType == "xero") {
      icon = xero.icon
    } else if (loginType == "QBO") {
      icon = qboButton.icon
    }
    else if (loginType == "zoho") {
      icon = zohoLogo.icon
    }
    else if (loginType == "odoo") {
      icon = zohoLogo.icon
      singInWithOdoo()
    }
  }

  const loading = useLoaderHelper()
  const loading2 = useLoaderHelper()
  const loginFrom = useFormik({
    initialValues: initialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async values => {
      if (window.sessionStorage.getItem("token")) {
        window.location.reload();
        return false
      }
      let formData: any = {
        ...values,
        loginType: loginType
      }

      auth.login(formData)
    }
  })
  return (
    <form className='form-horizontal form-login row' onSubmit={loginFrom.handleSubmit}>
      <div className='form-group'>
        <Label className='form-label' htmlFor="userName"> </Label>
        <input
          className="form-control"
          id="userName"
          name="userName"
          placeholder='User Name'
          type="text"
          onChange={loginFrom.handleChange}
          value={loginFrom.values.userName}
        />
        <FormFeedback style={{ display: loginFrom.touched.userName && loginFrom.errors.userName ? 'block' : '' }}>{loginFrom.errors.userName}</FormFeedback>

      </div>
      <div className='form-group'>
        <Label className='form-label' htmlFor="password"></Label>
        <input
          className="form-control"
          id="password"
          name="password"
          placeholder='Password'
          type="password"
          onChange={loginFrom.handleChange}
          value={loginFrom.values.password}
        />
        <FormFeedback style={{ display: loginFrom.touched.password && loginFrom.errors.password ? 'block' : '' }}>{loginFrom.errors.password}</FormFeedback>
      </div>
      <Col lg="12">
        <Button color='primary' block className="w-100" type="submit">{loading.dataIsLoading ? <Spinner size="sm" /> : 'Submit'}</Button>
      </Col>
      <Col lg="12" className='text-center position-relative'>
        <hr className='mx-4' />
        <span className='or_line'>or</span>
      </Col>
      <Col lg="12" className='text-center'>
        {
          loginType &&
          <Button color='primary' type='button' block className={"xero_login py-1 " + (loginType === "QBO" ? "qbo_login" : loginType === "zoho" ? "zoho_login" : "")} onClick={() => {
            if (window.sessionStorage.getItem("token")) {
              window.location.reload();
              return false
            }
            connectToAPP("/singleSignOn", { loginType: loginType }, loading)
          }}>{icon} {loading2.dataIsLoading ? <Spinner size="sm" /> : loginType + ' Login'} </Button>
        }
      </Col >

      <Col lg="12" className='text-center'>
        <Link to={loginType ? `/${loginType}/signUp` : '/signUp'} className="pt-3 d-inline-block mx-auto">
          Sign Up
        </Link>
      </Col>
      <Col lg="12" className='text-center'>
        <Link to="/ForgotPassword" className="pt-3 d-inline-block mx-auto">
          Forgot Password
        </Link>
      </Col>

    </form >
  )

}

export default Login